<template>
  <div class="body">
    <div class="orderItem">
      <span class="orderItem-label">患者姓名：</span>{{ name }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">住院号：</span>{{ patId }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">所在病区：</span>{{ dept }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">支付单号：</span>{{ orderid }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">支付时间：</span>{{ formatDate() }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">充值金额：</span
      ><span style="color: #e27a66"
        >￥{{ (parseInt(formData.amount) / 100).toFixed(2) }}</span
      >
    </div>
    <van-cell-group style="margin: 20px 0">
      <van-cell center>
        <template #title>
          <img
            src="@/assets/img/appwx_logo.png"
            alt=""
            class="appwx_logo"
          /><span class="appwx_text">微信支付</span>
        </template>
        <template #default>
          <van-radio-group v-model="checked">
            <van-radio :name="0" checked-color="#32D8C0"></van-radio>
          </van-radio-group>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="confirm-tips">请确认以上信息是否正确，再选择是否支付</div>
    <div class="confirm-tips">如需退费请到院内窗口办理</div>
    <div>
      <van-button class="pay-btn" @click="handleConfirm" color="#31AA05"
        >确认充值</van-button
      >
    </div>
    <!-- <div>
      <van-button class="pay-btn" color="#6B6B6B" @click="toClose"
        >关闭当前订单<br>
        <span style="font-size:0.3rem">(关闭后订单被删除,如需要则重新发起订单)</span></van-button
      >
    </div> -->
    <div>
      <van-button class="pay-btn" color="#8A8A8A" @click="toCancle"
        >取消支付</van-button
      >
    </div>
    <van-dialog
      class="dialog"
      v-model="show"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <div>请确认信息是否正确</div>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >患者姓名：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem"
          ><b>{{ name }}</b></van-col
        >
      </van-row>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >住院号：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem"
          ><b>{{ patId }}</b></van-col
        >
      </van-row>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >所在病区：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem"
          ><b>{{ dept }}</b></van-col
        >
      </van-row>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >支付单号：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem"
          ><b>{{ orderid }}</b></van-col
        >
      </van-row>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >支付时间：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem"
          ><b>{{ formatDate() }}</b></van-col
        >
      </van-row>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >充值金额：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem; color: #990000"
          ><b>￥{{ (parseInt(formData.amount) / 100).toFixed(2) }}</b></van-col
        >
      </van-row>
      <van-button type="primary" class="cancle-btn" @click="toConcle"
        >取消</van-button
      >
      <van-button type="primary" class="confirm-btn" @click="toPay"
        >确认</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import moment from "moment";
export default {
  name: "wxPayPage",
  data() {
    return {
      formData: {
        id: "",
        name: "",
        transactionId: "",
        changeTime: "",
        amount: "",
        status: "",
      },
      checked: 0,
      appId: "",
      timestamp: "",
      nonce: "",
      packageName: "",
      signType: "",
      signature: "",
      orderId: "",
      orderid: this.uuid(20, 16),
      id: "",
      dept: "",
      name: "",
      patId: "",
      show: false,
      showDialog: false,
      btnLoading: false,
    };
  },
  methods: {
    /**
     * 确认弹框
     */
    handleConfirm() {
      this.show = true;
    },
    /**
     * 关闭弹框
     */
    toConcle() {
      this.show = false;
    },
    /**
     * 确认支付
     */
    toPay() {
      this.btnLoading = true;
      this.show = false;
      let postData = {
        userid: sessionStorage.getItem("openid"),
        inpno: this.$route.query.inpno,
        amount: this.$route.query.price,
      };
      this.$http
        .post("/api/inhospital/inhospitaladd", postData)
        .then((result) => {
          this.btnLoading = false;
          this.appId = result.data.appId;
          this.timestamp = result.data.timeStamp;
          this.nonce = result.data.nonceStr;
          this.packageName = result.data.package;
          this.signType = result.data.signType;
          this.signature = result.data.paySign;
          this.orderId = result.data.orderId;
          this.isWeChat();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /**
     * 时间格式化
     */
    formatDate() {
      return moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    },
    /**
     * 取消支付
     */
    toCancle() {
      Toast({
        message: "取消成功！",
        duration: 500,
        onClose: () => {
          this.$router.push("/advancePayment");
        },
      });
    },
    /**
     * 关闭当前订单
     */
    toClose() {
      this.showDialog = true;
    },
    showData() {
      this.formData.amount = this.$route.query.price;
    },
    onBridgeReady() {
      let self = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: self.appId, //公众号名称，由商户传入
          timeStamp: self.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: self.nonce, //随机串
          package: self.packageName,
          signType: self.signType, //微信签名方式：
          paySign: self.signature, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            let postData = {
              id: self.orderId,
            };
            self.$http
              .get("/api/inhospital/confirm", postData)
              .then((result) => {
                Toast({
                  message: "充值成功！",
                  duration: 500,
                  onClose: () => {
                    self.$router.push({
                      path: "/advancePayment",
                    });
                  },
                });
              });
          }
        }
      );
    },
    isWeChat() {
      if (typeof WeixinJSBridge == "undefined") {
        this.onBridgeReady();
        Toast({ message: "请在微信客户端进行支付操作" });
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady(),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady());
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady());
        }
      } else {
        this.onBridgeReady();
      }
    },
    // 生成uuid的方法
    uuid(len, radix) {
      var chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
          ""
        );
      var uuid = [],
        i;
      radix = radix || chars.length;

      if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
      } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
        uuid[14] = "4";

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | (Math.random() * 16);
            uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
          }
        }
      }

      return uuid.join("");
    },
  },
  mounted() {
    this.showData();
    this.name = this.$route.query.name;
    this.patId = this.$route.query.inpno;
    this.dept = this.$route.query.dept;
  },
};
</script>

<style scoped>
.body {
  width: 9rem;
  height: auto;
  border-radius: 5px;
  margin: 0px auto;
  padding: 12px 3px;
  font-size: 0.4rem;
  line-height: 0.9rem;
}
.orderItem {
  text-align: left;
}
.orderItem-label {
  display: inline-block;
  width: 2.2rem;
  text-align: right;
}
.appwx_logo {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 10px;
}
.appwx_text {
  font-size: 0.5rem;
  vertical-align: middle;
}
.pay-btn {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
}

.confirm-tips {
  color: #5ba5cf;
  font-size: 0.4rem;
}
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}
.dialog p {
  text-align: left;
  font-size: 0.4rem;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}
.cancle-btn {
  width: 40%;
  height: 40px;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
/deep/ .van-cell {
  background-color: transparent;
  padding: 10px 0;
}
/deep/ .van-cell-group {
  background-color: transparent;
}
/deep/ .van-cell__title {
  text-align: left;
}
/deep/ .van-radio {
  float: right;
  clear: both;
}
/deep/ .van-button {
  padding: 5px 0;
}
</style>
